import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { CustomReelVideo } from "../components/videos/custom-reel-video";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background-color: #000;
  min-height: 100vh;

  color: #fff;

  overflow-x: hidden;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;

  padding: 50px 0;

  & button.bio-button {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 18px;
    line-height: 151%;
    letter-spacing: 0.1em;
    font-variant: small-caps;
    color: rgba(253, 252, 252, 0.8);
  }
`;

const Title = styled.div`
  width: 100%;
  text-align: center;

  & h1 {
    font-size: 72px;
    line-height: 115%;

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
`;

const CustomReelContent = ({ data, setPageType }) => {
  const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState("");
  const [shouldImageFadeOut, setImageFadeOut] = useState(false);
  const [allowPointerEvents, setAllowPointerEvents] = useState("none");
  const [onReady, setOnReady] = useState(false);

  useEffect(() => {
    setPageType("directors");
  }, []);

  useEffect(() => {
    setAllowPointerEvents(`all`);
  }, [onReady, setAllowPointerEvents]);

  const firstDirectorVideo = data.prismicCustomReel.data.videos
    .filter((content, index) => index === 0)
    .filter(content => content.video.document !== null)
    .map((content, index) => (
      <CustomReelVideo
        key={`director_video_${data.prismicCustomReel.uid}_${index}`}
        content={content}
        index={index}
        customReelUid={data.prismicCustomReel.uid}
      />
    ));

  const remainingDirectorVideos = data.prismicCustomReel.data.videos
    .filter((content, index) => index !== 0)
    .filter(content => content.video.document !== null)
    .map((content, index) => (
      <CustomReelVideo
        key={`director_video_${data.prismicCustomReel.uid}_${index}_remaining_videos`}
        content={content}
        index={index}
        customReelUid={data.prismicCustomReel.uid}
      />
    ));

  return (
    <Page>
      <Container>{firstDirectorVideo}</Container>

      <TextContainer>
        <Title
          dangerouslySetInnerHTML={{
            __html: data.prismicCustomReel.data.title.html,
          }}
        />
      </TextContainer>

      <Container>{remainingDirectorVideos}</Container>
    </Page>
  );
};

const CustomReel = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <CustomReelContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(CustomReel);

export const query = graphql`
  query CustomReel($uid: String!) {
    prismicCustomReel(uid: { eq: $uid }) {
      uid
      prismicId
      data {
        videos {
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  vimeo_video {
                    html
                    embed_url
                    width
                    description
                    title
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        uid
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
        title {
          html
          text
        }
      }
    }
  }
`;
